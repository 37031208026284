exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-christmas-order-js": () => import("./../../../src/pages/christmas-order.js" /* webpackChunkName: "component---src-pages-christmas-order-js" */),
  "component---src-pages-christmas-sold-out-js": () => import("./../../../src/pages/christmas-sold-out.js" /* webpackChunkName: "component---src-pages-christmas-sold-out-js" */),
  "component---src-pages-christmas-thank-you-js": () => import("./../../../src/pages/christmas-thank-you.js" /* webpackChunkName: "component---src-pages-christmas-thank-you-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-gift-cards-js": () => import("./../../../src/pages/gift-cards.js" /* webpackChunkName: "component---src-pages-gift-cards-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menus-brunch-js": () => import("./../../../src/pages/menus/brunch.js" /* webpackChunkName: "component---src-pages-menus-brunch-js" */),
  "component---src-pages-menus-dessert-js": () => import("./../../../src/pages/menus/dessert.js" /* webpackChunkName: "component---src-pages-menus-dessert-js" */),
  "component---src-pages-menus-dinner-js": () => import("./../../../src/pages/menus/dinner.js" /* webpackChunkName: "component---src-pages-menus-dinner-js" */),
  "component---src-pages-menus-drinks-js": () => import("./../../../src/pages/menus/drinks.js" /* webpackChunkName: "component---src-pages-menus-drinks-js" */),
  "component---src-pages-menus-index-js": () => import("./../../../src/pages/menus/index.js" /* webpackChunkName: "component---src-pages-menus-index-js" */),
  "component---src-pages-menus-lunch-js": () => import("./../../../src/pages/menus/lunch.js" /* webpackChunkName: "component---src-pages-menus-lunch-js" */),
  "component---src-pages-private-events-js": () => import("./../../../src/pages/private-events.js" /* webpackChunkName: "component---src-pages-private-events-js" */),
  "component---src-pages-reservations-js": () => import("./../../../src/pages/reservations.js" /* webpackChunkName: "component---src-pages-reservations-js" */),
  "component---src-pages-special-events-js": () => import("./../../../src/pages/special-events.js" /* webpackChunkName: "component---src-pages-special-events-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thanksgiving-order-js": () => import("./../../../src/pages/thanksgiving-order.js" /* webpackChunkName: "component---src-pages-thanksgiving-order-js" */),
  "component---src-pages-thanksgiving-thank-you-js": () => import("./../../../src/pages/thanksgiving-thank-you.js" /* webpackChunkName: "component---src-pages-thanksgiving-thank-you-js" */)
}

